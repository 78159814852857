@import './mixins/_mixins.scss';

table {
	border-collapse: collapse;
	width: 100%;
  th {
	background-color: $primary-color;
	font-weight: bold;
	color: $color-white;
	font-weight: bold;
	font-family: $secondary-font;
	font-size: $reading-font-size * 1.2;
	line-height: $reading-line-height;
	padding: 12px;
  }
  
  td {
	border: 1px solid #ddd;
	color: $color-black;
	font-family: $primary-font;
	font-size: $reading-font-size;
	line-height: $reading-line-height;
	padding: 12px;
	text-align: center;
  }
  
  tr:nth-child(even) {
	background-color: $secondary-color;
  }
}