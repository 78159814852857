@import './mixins/_mixins.scss';
footer {
	&,
	&:before,
	&:after {
		background-color: $secondary-color;
	}
	
	display: flex;
	flex-direction: row;
	flex-direction: column;
	text-align: center;
	font-style: italic;
	justify-content: center;
    align-items: center;

	min-height: 100px;
	height: fit-content;
	padding-bottom: 1rem;
	
	p {
		color: $color-black;
	}
	a {
		color: black;
		text-decoration: none;

		&:hover{
			color: gray;
		}
	}

	.clown-network{
		margin-top: 1rem;
		font-size: 14px;
		line-height: 1;
		gap: 1rem;
		display: flex;
		flex-direction: row;
		font-style: normal;
	}
	
}