@import './mixins/_mixins.scss';

header {
	&,
	&:before,
	&:after {
		background-color: $primary-color;
	}

	.menu-main {
		display: flex;
		flex-direction: row;
		gap: 2rem;

		a.menu-item {
			color: $font-black;
			display: flex;
			align-items: center;
			text-decoration: none;
			padding: 1rem 0;
			height: 100%;
			cursor: pointer;
			
			-webkit-transition: $transitions;
			-moz-transition: $transitions;
			-o-transition: $transitions;
			transition: $transitions;
			
			&.active {
				font-weight: 900;
			}

			&:hover {
				color: green;
				font-weight: bold;
			}
		}
	}
}