$reading-font-size: 14px;
$reading-line-height: 1;

@import 'fonts/carnival/carnival.css';
@import 'fonts/circus/Circus.css';

// Define fonts
$primary-font: 'Comic Sans', sans-serif;
$secondary-font: 'carnival', sans-serif;


// Define fonts
$primary-font: 'Comic Sans', sans-serif;
// $secondary-font: 'Circus', sans-serif;
$secondary-font: 'Comic Sans', sans-serif;
