@import './mixins/_mixins.scss';


// Header styles
header {
  font-family: $primary-font;
}

// Footer styles
footer {
  font-family: $secondary-font;
}

// H1 styles
h1 {
  color: $tertiary-color;
  font-family: $secondary-font;
}

// H2 styles
h2 {
  color: $primary-color;
  font-family: $primary-font;
}

// H3 styles
h3 {
  color: $secondary-color;
  font-family: $secondary-font;
}

// Link styles
a {
  color: $tertiary-color;
  font-family: $primary-font;
}

// Paragraph styles
p {
  color: $primary-color;
  font-family: $secondary-font;
}