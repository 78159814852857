@import './mixins/_mixins.scss';

nav {
	// background: #63D471;
	height: 85px;
	display: flex;
	justify-content: space-between;
	display: flex; 
	z-index: 12;
	/* Third Nav */
	/* justify-content: flex-start; */
	
	a.menu-link {
		color: white;
		display: flex;
		align-items: center;
		text-decoration: none;
		padding: 0 1rem;
		height: 100%;
		transition: $transitions;
		cursor: pointer;

		&:active {
			color: #000000;
		}
		&:hover {
			color: white;
			color: $secondary-color;
		}

		&.logo-link {
			padding: unset;
			.main-logo{
				height: 100%;
				width: auto;
				aspect-ratio: auto;
			}
		}
	}

}