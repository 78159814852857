$font-black: #222;
$transitions: all 0.25s 0s ease-in-out;


// Define colors
$color-black: #000000;
$color-white: #ffffff;
$primary-color: #FFA07A;
$secondary-color: #ADD8E6;
$tertiary-color: #7FFFD4;

