@import './mixins/_mixins.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$max-body-width: 1600px;
:root {
  --max-body-width: $max-body-width;
  --overflow-width: 10vw;
}

.full-screen-element{
  position: relative!important;
  
  @media only screen and (min-width: 2010px) {
    --inside: calc(100vw - var(--max-body-width));
    --overflow-width: calc( calc(var(--inside)/2) - 0px);
  }
  
  &:before,
  &:after {
    content: "";
    top: 0px;
    bottom: 0px; 
    width: var(--overflow-width);
    overflow: visible;
    position: absolute;
    z-index: -1;
  }

  &:before{
    // background-color: orange!important;
    left: 0;
    margin-left: calc(-1 * var(--overflow-width));
  }
  &:after{
    // background-color: green!important;
    right: 0px;
    margin-right: calc(-10% - var(--max-body-width));
    margin-right: calc(-1 * var(--overflow-width));
  }
}

html, body {
  display: flex;
  flex-direction: column;
  align-items: center;
}


html {
  // border: solid 5px red;
  min-height:100vh;
  max-width: 100vw;
  margin: 0px 10%;

  & * {
    -webkit-transition: $transitions;
    -moz-transition: $transitions;
    -o-transition: $transitions;
    transition: $transitions;
  }
  
  body {
    max-width: $max-body-width;
    
    align-items: center;
    overflow-x: hidden;
    width: 100%;
      
    flex: 1 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    
    > * {
      width: 100%;
    }

    /*
    header {
      // background-color: green;
      // border: solid 2px green;
    }
    main {
      // background-color: blue;
      // border: solid 2px blue;
    }

    footer {
      // background-color: violet;
      // border: solid 2px violet;
    }
    */
  }
}

